import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { DataService } from "../../helpers/data.service";
import { Router } from "@angular/router";
import { FlyerService } from "../../services/flyer.service";
import { PostageType } from "../../models/postage";
import { Campaign } from "../../models/campaign";
import { PDFDocumentProxy } from "ng2-pdf-viewer";
import { MatDialog } from "@angular/material/dialog";
import { FileViewerComponent } from "../../components/file-viewer/file-viewer.component";
import { UploadedFile } from "src/app/models/uploaded-file";
import { CampaignService } from "../../services/campaign.service";
import { Subject } from "rxjs";
import { CampaignStatus } from "../../utils/campaign-status";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-flyer",
  templateUrl: "./flyer.component.html",
  styleUrls: ["./flyer.component.css"],
})
export class FlyerComponent implements OnInit {
  public postageTypes: PostageType[];
  campaign: Campaign;
  flyer: UploadedFile = {};
  logo: UploadedFile = {};
  Object = Object;
  Number = Number;
  uploadedDone: Subject<boolean> = new Subject();

  @ViewChild("pdfViewer", { static: false }) pdfViewer: TemplateRef<any>;

  constructor(
    private dataService: DataService,
    private router: Router,
    private flyerService: FlyerService,
    private dialog: MatDialog,
    private campaignService: CampaignService,
    public translate: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (!this.dataService.state.user.flyerStep) {
      this.router.navigate(["/"]);
    }
    this.postageTypes = this.dataService.state.user.postage;
    this.campaign = this.dataService.state.campaign || {};
    this.campaign.postage = {
      selectedPostageType: this.postageTypes.filter(
        (postage) => postage.default
      )[0],
      price: 0,
    };
  }

  uploadFile(event) {
    let file = event.target.files[0];
    if (event.target.name === "flyer") {
      if (file.type === "application/pdf") {
        this.flyer.file = file;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          this.flyer.url = e.target["result"];
          this.flyer.name = `${this.campaign.id}_flyer.pdf`;
        };
      } else {
        this.flyer.file = null;
      }
    } else if (event.target.name === "logo") {
      if (
        file.type === "application/pdf" ||
        file.type.split("/")[0] === "image"
      ) {
        this.logo.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.logo.url = e.target["result"];
          if (file.type === "application/pdf") {
            this.logo.name = `${this.campaign.id}_logo.pdf`;
          } else if (file.type.split("/")[0] === "image") {
            let ext = file.type.split("/")[1];
            this.logo.name = `${this.campaign.id}_logo.${ext}`;
          }
        };
      } else {
        this.logo.file = null;
      }
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy, elm: string) {
    if (elm === "flyer") {
      if (pdf.numPages > 2) {
        pdf.destroy();
        this.flyer = { file: null };
      }
    } else if (elm === "logo") {
      if (pdf.numPages > 1) {
        pdf.destroy();
        this.logo = { file: null };
      }
    }
  }

  showFile(file) {
    this.dialog.open(FileViewerComponent, { data: { file: file } });
  }

  upload() {
    this.campaign.status = CampaignStatus.flyerStep;
    let campaign = Object.assign({}, this.campaign, {
      numberOfAddressesArray: undefined,
    });
    this.campaignService.update(campaign).subscribe(
      (data) => {
        this.campaign.price = data["price"];
        this.dataService.setCampaign(this.campaign);
        this.uploadToS3();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  uploadToS3() {
    let flyerPromise = this.flyerService.uploadToS3(
      this.flyer,
      this.dataService.state.user.s3config
    );

    let logoPromise = this.flyerService.uploadToS3(
      this.logo,
      this.dataService.state.user.s3config
    );

    Promise.all([flyerPromise, logoPromise])
      .then(() => this.router.navigate(["/recapitulate"]))
      .catch((err) => {
        console.error(err);
      });
  }

  getPriceByNb(postage: PostageType) {
    let price;
    postage.prices.map((elm) => {
      if (this.campaign.numberOfAddresses >= elm.range) {
        price = elm.price;
      }
    });

    if (!price) {
      price = postage.prices[0].price;
    }
    return price;
  }

  showPrice() {
    let priceByRange = this.getPriceByNb(
      this.campaign.postage.selectedPostageType
    );

    if (!this.campaign.postage.selectedPostageType.default) {
      let basePrice = this.getPriceByNb(
        this.postageTypes.filter((postage) => postage.default)[0]
      );
      priceByRange -= basePrice;
      this.campaign.postage.price = priceByRange;
      return `
          ${Number(this.campaign.numberOfAddresses * priceByRange).toFixed(
            2
          )} ${this.translate.instant("flyer-step.pay-per-adr")}
          `;
    } else {
      this.campaign.postage.price = 0;
      return this.translate.instant("flyer-step.already-included");
    }
  }
}
