import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { UploadedFile } from "../models/uploaded-file";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FlyerService {
  constructor(private http: HttpClient) {}

  // uploadToS3(uploadedFile: UploadedFile, s3config: any) {
  //   const bucket = new S3({
  //     accessKeyId: s3config.accessKeyId,
  //     secretAccessKey: s3config.accessKeySecret,
  //     region: s3config.region,
  //   });
  //   const params = {
  //     Bucket: s3config.bucket,
  //     Key: s3config.uploadFolder + uploadedFile.name,
  //     Body: uploadedFile.file,
  //     ContentType: uploadedFile.file.type,
  //   };

  //   return bucket.upload(params);
  // }
  //`${environment.baseUrl}/addressOrder/init`

  // Frontend code
  uploadToS3(uploadedFile: UploadedFile, s3config: any) {
    // Convert the file data to base64 if needed
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const base64Data = reader.result as string;
        const payload = {
          key: s3config.uploadFolder + uploadedFile.name,
          data: base64Data.split(",")[1], // Remove the data:image/xxx;base64, prefix
          contentType: uploadedFile.file.type,
        };

        this.http
          .post(`${environment.baseUrl}/campaign/upload`, payload)
          .pipe(
            map((response: any) => {
              if (response.success) {
                return response;
              } else {
                throw new Error("Upload failed");
              }
            })
          )
          .subscribe({
            next: (result) => resolve(result),
            error: (error) => reject(error),
          });
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      reader.readAsDataURL(uploadedFile.file);
    });
  }
}
