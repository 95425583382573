import * as MapboxGL from "mapbox-gl";
import domtoimage from "dom-to-image";
import pptxgen from "pptxgenjs";
import pointInPolygon from "@turf/boolean-point-in-polygon";
import polygonUnion from "@turf/union";
import polygonBuffer from "@turf/buffer";
import polygonTruncate from "@turf/truncate";
import * as S3 from "aws-sdk/clients/s3";

import { Departement } from "./dep-data";
import { User } from "../../models/user";
import { environment } from "../../../environments/environment";
import addSlide1 from "./slides/1";
import addSlide2 from "./slides/2";
import addSlide3 from "./slides/3";
import addSlide4 from "./slides/4";
import addSlide5 from "./slides/5";
import addSlide6 from "./slides/6";
import addSlide7 from "./slides/7";
import addStatSlides from "./slides/stat";
import addCompetitionSlides from "./slides/competition";
import addFinalSlide from "./slides/final";
import { Elm } from "src/app/models/elm";

const BACKGROUND_STYLE = "mapbox://styles/mapbox/streets-v11";

const isInputValid = (input) => {
  if (Array.isArray(input)) {
    return input.length > 0;
  }
  return Boolean(input);
};

const last = (array) => array[array.length - 1];

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const toPercent = (_) => `${Math.round(_ * 100)}%`;

const sum = (array) => array.reduce((acc, value) => acc + value, 0);

const avg = (array) => sum(array) / array.length;

// only keep the first occurence of a value in array, depending of the lambda result
const distinctBy = <T>(array: T[], lambda: (arg: T) => any): T[] =>
  array.filter(
    (elt, index) => index === array.findIndex((_) => lambda(_) === lambda(elt))
  );

const applyCss = (elt, style) => {
  Object.entries(style).forEach(([key, value]) => (elt.style[key] = value));
};

//Pour la Corse: 8.5347,41.3332,9.5601,43.0277
//France: -5.45,41.26,9.83,51.31
const franceBounds: number[][] = [
  [-5.45,41.26],
  [9.83,51.31],
];

//transform an array into an object. Key is determined by a lambda
//in: ([0, 3, 5, 7, 6], _ => _ % 2)
//out: {0: [0, 6], 1: [3, 5, 7]}
export const groupBy = <T>(
  array: T[],
  lambda: (arg: T, index?: number) => any
): { [key: string]: T[] } =>
  array.reduce((acc: any, value: T) => {
    const key = lambda(value);
    return {
      ...acc,
      [key]: acc[key] ? acc[key].concat(value) : [value],
    };
  }, {});

const mergePolygons = (polygons) => {
  // we use truncate as a workaround to this bug https://github.com/Turfjs/turf/issues/2258
  const merged = polygons.map(_ => polygonTruncate(_)).reduce((a, b) => polygonUnion(a, b));
  merged.properties = polygons[0].properties;
  return merged;
};

const toFixed = (num, digit) => {
  const factor = Math.pow(10, digit);
  return (Math.round(num * factor) / factor).toLocaleString();
};

const setDevicePixelRatio = async (pixelRatio) => {
  Object.defineProperty(window, "devicePixelRatio", {
    get: function () {
      return pixelRatio;
    },
  });
  await sleep(1000); // it takes a little while to apply
};

const getImageDimensions = async (dataUrl) => {
  const img = document.createElement("img");
  img.src = dataUrl;
  return new Promise((resolve, reject) => {
    img.onload = function () {
      const { width, height } = img;
      resolve({ width, height });
    };
    img.onerror = reject;
  });
};

const element2png = async (element, scale = 1, bgcolor = undefined) =>
  domtoimage.toPng(element, {
    width: element.clientWidth * scale,
    height: element.clientHeight * scale,
    style: {
      transform: `scale(${scale})`,
      transformOrigin: "top left",
    },
    bgcolor,
  });

const getColorPalette = (
  nbColors,
  hue = 200,
  sat = 0.6,
  minLum = 0.4,
  maxLum = 1
) => {
  return Array(nbColors - 1)
    .fill(null)
    .map((_, index) => {
      const lum = minLum + (index / (nbColors - 1)) * (maxLum - minLum);
      const color = `hsl(${hue}, ${toPercent(sat)}, ${toPercent(lum)})`;
      return color;
    })
    .concat(`hsl(${hue}, ${toPercent(sat)}, ${toPercent(maxLum)})`);
};

const getPaletteExpression = (
  selector,
  referencePoints,
  noData = null
): any => {
  const res = [
    "case",
    ["==", ["coalesce", selector, -1], -1],
    "black", // if no data, then black
  ];
  if (noData !== null) {
    res.push(
      ...[
        ["==", selector, noData],
        "black", // if data is eq. to no data, then black
      ]
    );
  }

  res.push([
    "interpolate",
    ["linear"],
    selector,
    ...referencePoints
      .map(({ value, color }) => [value, color])
      .reverse()
      .flat(),
  ]);

  return res;
};

const addBoundsMargin = (bounds, relativeMargin): any => {
  const [southWest, northEast] = bounds;
  const [minLon, minLat] = southWest;
  const [maxLon, maxLat] = northEast;
  const lonMargin = (maxLon - minLon) * relativeMargin;
  const latMargin = (maxLat - minLat) * relativeMargin;
  return [
    [minLon - lonMargin, minLat - latMargin],
    [maxLon + lonMargin, maxLat + latMargin],
  ];
};

interface Stat {
  property: string;
  title: string;
  legendTitle?: string;
  relativeToAvg?: boolean;
  thresholds?: number[];
  nbThresholds?: number;
  roundingValue?: number;
  noData?: number;
  dataModifier?: (value: any) => any;
  transform?: (value: any) => string;
  relativeTransform?: (value: any) => string;
  legends?: string[];
  showSum?: boolean;
  showNationalAvg?: boolean;
  showAvgLegend?: boolean;
  disableDataSlide?: boolean;
}

const stats: Stat[] = [
  {
    property: "rev",
    title: "Revenu Médian",
    nbThresholds: 3,
    roundingValue: 500,
    noData: 0,
    transform: (value) => `${Math.round(value).toLocaleString()}€`,
    showNationalAvg: true,
    showAvgLegend: true,
  },
  {
    property: "p_cspp",
    title: "Typologie Population - CSP+",
    nbThresholds: 3,
    roundingValue: 0.05,
    transform: (value) => `${toFixed(value * 1e2, 2)}%`,
    showNationalAvg: true,
    showAvgLegend: true,
  },
  {
    property: "p_fam",
    title: "Typologie Population - Familles",
    legendTitle:
      "% de familles avec 2 enfants, par rapport à la moyenne nationale",
    relativeToAvg: true,
    thresholds: [1.5, 1.0, 0.5, 0],
    transform: (value) => `${toFixed(value * 1e2, 2)}%`,
    relativeTransform: (value) => `${value}x la moyenne`,
    showNationalAvg: true,
  },
  {
    property: "p_retr",
    title: "Typologie Population - Retraités",
    legendTitle: "% de Retraités, par rapport à la moyenne nationale",
    relativeToAvg: true,
    thresholds: [2, 1, 0],
    transform: (value) => `${toFixed(value * 1e2, 2)}%`,
    relativeTransform: (value) => `${value}x la moyenne`,
    showNationalAvg: true,
  },
  {
    property: "p_mais",
    title: "Habitat - Maisons principales",
    legendTitle: "% de Maisons principales",
    nbThresholds: 3,
    roundingValue: 0.1,
    transform: (value) => `${toFixed(value * 1e2, 2)}%`,
    showNationalAvg: true,
    showAvgLegend: true,
  },
  {
    property: "nb_pools",
    title: "Données Piscines - Piscines estimées",
    legendTitle: "Piscines / Iris",
    nbThresholds: 3,
    roundingValue: 5,
    transform: (value) => toFixed(value, 2),
    showSum: true,
  },
  {
    property: "nb_po_ad",
    title: "Données Piscines - Piscines adressables",
    legendTitle: "Piscines / Iris",
    nbThresholds: 3,
    roundingValue: 5,
    transform: (value) => toFixed(value, 2),
    showSum: true,
  },
  {
    property: "perc_pot",
    title: "Données Piscines - Croissance potentielle",
    legendTitle: "Piscines / Iris",
    thresholds: [30, 6, -6, -30],
    transform: (value) => toFixed(value, 2),
    legends: ["Croissance forte", "Croissance", "Saturé", "Saturation forte"],
    disableDataSlide: true,
  },
];

class ELMGenerator {
  dep?: string;
  irises?: string[];
  zipCodes?: string[];
  containerElt: HTMLElement;
  user: User;
  mapElt: HTMLElement;
  legendElt: HTMLElement;
  map: MapboxGL.Map;
  irisSource: any;
  depFeature: any;
  irisFeatures: any;
  elmData: any;
  pres: pptxgen;
  logo: any;
  logoDimensions: any;

  constructor(containerElt: HTMLElement, user: User) {
    this.containerElt = containerElt;
    this.user = user;

    this.elmData = {};
  }

  async _takeMapShot() {
    return await this.map.getCanvas().toDataURL("image/jpeg");
  }

  async _setStatMap(stat: Stat) {
    const referencePoints = this._getReferencePoints(stat, stat.relativeToAvg);

    this.map.addLayer(
      {
        id: "iris-fill",
        type: "fill",
        source: this.irisSource.name,
        "source-layer": this.irisSource.name,
        filter: this._getIrisFilter(),
        paint: {
          "fill-color": getPaletteExpression(
            ["get", stat.property],
            referencePoints,
            stat.noData
          ),
          "fill-opacity": 1,
        },
      },
      "road-primary"
    );

    this.map.addLayer(
      {
        id: "iris-line",
        type: "line",
        source: this.irisSource.name,
        "source-layer": this.irisSource.name,
        filter: this._getIrisFilter(),
        paint: {
          "line-color": "black",
          "line-opacity": 0.08,
          "line-width": 0.5
        },
      },
      "road-primary"
    );

    await this._waitForMapIdle();
  }

  _getReferencePoints(stat: Stat, relativeToAvg: boolean = false) {
    const data = this.irisFeatures
      .map((_) => _.properties[stat.property])
      .filter((_) => _ !== stat.noData)
      .map((_) => {
        if (stat.dataModifier) return stat.dataModifier(_);
        return _;
      })
      .sort((a, b) => b - a);

    // we need to get national avg
    const { property, thresholds } = stat;
    const { properties } = this.irisFeatures[0]; // any feature will provide the same result
    const nationalAvg = properties[property] - properties[`${property}_d`];

    const nbThresholds = thresholds ? thresholds.length : stat.nbThresholds;

    // const minBy = (array, lambda) => {
    //   let min = Number.POSITIVE_INFINITY;
    //   let index = -1;
    //   array.forEach((elt, i) => {
    //     const value = lambda(elt);
    //     if (value < min) {
    //       min = value;
    //       index = i;
    //     }
    //   });
    //   if (index === -1) return null;
    //   return array[index];
    // };

    // const dynamicRound = value => {
    //   if(value === 0) return 0;
    //   const mulDigits = [1, 2, 5, 10]
    //   const log =  Math.floor(Math.log10(value));
    //   const pow10 = Math.pow(10, log)
    //   const digit = value / pow10;
    //   const roundedDigit = minBy(mulDigits, _ => Math.abs(digit - _))
    //   console.log({log, digit, roundedDigit, value})
    //   return pow10 * roundedDigit;
    // }

    // let valuePoints;
    // if (thresholds) {
    //   valuePoints = Array(nbThresholds + 2)
    //     .fill(null)
    //     .map((_, index) => {
    //       let value;
    //       if (index === 0) {
    //         value = Math.max(...data, ...thresholds);
    //       } else if (index === nbThresholds + 1) {
    //         value = Math.min(...data, ...thresholds);
    //       } else {
    //         value = thresholds[index - 1];
    //       }
    //       if (relativeToAvg) {
    //         value *= nationalAvg;
    //       }
    //       return value;
    //     })
    //     .filter((value, i, arr) => arr.indexOf(value) === i); // we remove duplicates
    // } else {
    //   let mulIndex = 0;
    //   let mulLog = Math.round(Math.log10(data[0]));
    //   let stop = 20;
    //   valuePoints = [];
    //   do {
    //     const roundingValue = mulDigits[mulIndex] * Math.pow(10, mulLog);
    //     const newValuePoints = Array(nbThresholds + 2)
    //       .fill(null)
    //       .map((_, index) => {
    //         const minData =
    //           data[
    //             Math.round((data.length - 1) * (index / (nbThresholds + 1)))
    //           ];
    //         return Math.round(minData / roundingValue) * roundingValue;
    //       })
    //       .filter((value, i, arr) => arr.indexOf(value) === i); // we remove duplicates
    //     if (newValuePoints.length > valuePoints.length) {
    //       valuePoints = newValuePoints;
    //     }
    //     console.log({
    //       stat: stat.title,
    //       mulIndex,
    //       mulLog,
    //       roundingValue,
    //       valuePoints,
    //     });
    //     if (mulIndex === 0) mulLog--;
    //     mulIndex = (mulIndex - 1 + mulDigits.length) % mulDigits.length;
    //     stop--;
    //   } while (stop > 0 && valuePoints.length <= nbThresholds);
    //   valuePoints = valuePoints.map(dynamicRound)
    // }

    const valuePoints = Array(nbThresholds + 2)
      .fill(null)
      .map((_, index) => {
        if (thresholds) {
          let value;
          if (index === 0) {
            value = Math.max(...data, ...thresholds);
          } else if (index === nbThresholds + 1) {
            value = Math.min(...data, ...thresholds);
          } else {
            value = thresholds[index - 1];
          }
          if (relativeToAvg) {
            value *= nationalAvg;
          }
          return value;
        } else {
          const minData =
            data[Math.round((data.length - 1) * (index / (nbThresholds + 1)))];
          return Math.round(minData / stat.roundingValue) * stat.roundingValue;
        }
      })
      .filter((value, i, arr) => arr.indexOf(value) === i); // we remove duplicates

    const colors = getColorPalette(valuePoints.length);
    return valuePoints.map((value, index) => ({ value, color: colors[index] }));
  }

  _buildLegendLine(text: string, leftElt?: Element) {
    const line = document.createElement("div");
    if (leftElt) {
      line.appendChild(leftElt);
    }
    const legendText = document.createElement("span");
    const hyphen = document.createElement("span");
    hyphen.innerHTML = "— ";
    applyCss(hyphen, { color: "#484F56" });
    legendText.appendChild(hyphen);
    const textElt = document.createElement("span");
    textElt.innerHTML = text;
    legendText.appendChild(textElt);
    applyCss(legendText, {
      "margin-left": leftElt ? "0em" : "1em",
    });
    line.appendChild(legendText);
    return line;
  }

  _setIrisLegend(stat: Stat, data: any) {
    const referencePoints = this._getReferencePoints(stat);

    if (stat.legendTitle) {
      const legendTitleElt = document.createElement("div");
      legendTitleElt.innerHTML = stat.legendTitle;

      this.legendElt.appendChild(legendTitleElt);
    }

    const legendBodyElt = document.createElement("div");
    this.legendElt.appendChild(legendBodyElt);
    applyCss(legendBodyElt, {
      position: "relative",
    });
    const legendGradient = document.createElement("div");
    const colors = referencePoints.map(({ color }) => color);
    applyCss(legendGradient, {
      position: "absolute",
      top: "4px",
      left: 0,
      bottom: "2px",
      "border-top-left-radius": "0.2em",
      "border-bottom-left-radius": "0.2em",
      border: "1px solid #484F56",
      background: `linear-gradient(to bottom,${colors.join(", ")})`,
      width: "1em",
      margin: "0.5em 0",
    });
    legendBodyElt.appendChild(legendGradient);

    const { relativeToAvg, relativeTransform, showAvgLegend } = stat;
    const transform = relativeToAvg ? relativeTransform : stat.transform;
    const thresholds = referencePoints.map(({ value }) => value);

    const legends = stat.legends || thresholds.map(transform);
    legends.forEach((legend) => {
      legendBodyElt.appendChild(this._buildLegendLine(legend));
    });

    if (showAvgLegend) {
      const { nationalAvg } = data;
      const index = thresholds.findIndex((value, i) => {
        const nextValue = thresholds[i + 1];
        return nationalAvg < value && nationalAvg >= nextValue;
      });
      if (index > -1) {
        const min = thresholds[index + 1];
        const max = thresholds[index];
        const relativePos =
          (index + 1 - (nationalAvg - min) / (max - min)) /
          (thresholds.length - 1);
        const lineContainer = document.createElement("div");
        applyCss(lineContainer, {
          position: "relative",
          width: "100%",
          height: "100%",
        });
        legendGradient.appendChild(lineContainer);
        const avgLine = document.createElement("hr");
        applyCss(avgLine, {
          position: "absolute",
          top: `${Math.round(relativePos * 100)}%`,
          width: "calc(1em - 3px)",
          left: 0,
          margin: 0,
          "margin-top": "-1px",
          border: "1px dashed #484F56",
        });
        lineContainer.appendChild(avgLine);
        const line = document.createElement("hr");
        applyCss(line, {
          display: "inline-block",
          border: "1px dashed #484F56",
          "margin-top": "calc(0.5em - 1px)",
          "margin-bottom": "calc(0.5em - 1px)",
          width: "calc(1em - 3px)",
        });
        this.legendElt.appendChild(
          this._buildLegendLine("Moyenne nationale", line) //unbreakable space
        );
      }
    }

    if (stat.noData !== undefined) {
      const blackSquare = document.createElement("span");
      applyCss(blackSquare, {
        display: "inline-block",
        "border-radius": "20%",
        border: "1px solid #484F56",
        width: "1em",
        height: "1em",
        "background-color": "black",
      });
      this.legendElt.appendChild(
        this._buildLegendLine("Pas de données", blackSquare)
      );
    }
  }

  _getIrisData(stat: Stat) {
    const { property } = stat;

    // if fixed thresholds are not provided, we need to get them from iris data
    // if we show values relative to national avg, we need to get avg from iris data

    const data = this.irisFeatures
      .map((_) => _.properties[property])
      .filter((_) => _ !== stat.noData)
      .map((_) => {
        if (stat.dataModifier) return stat.dataModifier(_);
        return _;
      })
      .sort((a, b) => b - a);

    // we need to get national avg
    const { properties } = this.irisFeatures[0]; // any feature will provide the same result
    const nationalAvg = properties[property] - properties[`${property}_d`];

    return {
      nationalAvg,
      zoneSum: sum(data),
      zoneAvg: avg(data),
      zoneMin: last(data),
      zoneMax: data[0],
    };
  }

  async _generateIrisStat(stat: Stat) {
    await this._setStatMap(stat);
    const mapImg = await this._takeMapShot();
    this.map.removeLayer("iris-fill"); // we clear layer
    this.map.removeLayer("iris-line"); // we clear layer

    const data = this._getIrisData(stat);

    this._setIrisLegend(stat, data);
    const legendImg = await element2png(this.legendElt, 2);
    this.legendElt.innerHTML = ""; // we clear legend

    this.elmData[stat.property] = {
      map: mapImg,
      legend: legendImg,
      legendDimensions: await getImageDimensions(legendImg),
      data,
    };
  }

  async _waitForMapIdle() {
    await new Promise((resolve) => this.map.once("idle", resolve));
  }

  _loadMapSources() {
    this.irisSource = environment.mapbox.geostrat.sources[2];
    this.map.addSource(this.irisSource.name, {
      type: "vector",
      url: this.irisSource.url,
    });
  }

  async _getLogo() {
    const { s3config, logoKey } = this.user;
    if (!logoKey) return null;
    const bucket = new S3({
      accessKeyId: s3config.accessKeyId,
      secretAccessKey: s3config.accessKeySecret,
      region: s3config.region,
    });
    const { Body, ContentType }: any = await bucket
      .getObject({
        Bucket: s3config.bucket,
        Key: logoKey,
      })
      .promise();
    const b64encoded = btoa(
      Body.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
    return `data:${ContentType};base64,${b64encoded}`;
  }

  _getIrisFilter() {
    if (this.dep) {
      return ["==", this.dep, ["get", "code_dep"]];
    } else if (this.irises && this.irises.length > 0) {
      return ["in", ["get", "code_iris"], ["literal", this.irises]];
    } else {
      // zipCodes
      return [
        "any",
        ...this.zipCodes.map((zipCode) => [
          "==",
          0,
          ["index-of", zipCode, ["get", "code_iris"]],
        ]),
      ];
    }
  }

  async _initMapData() {
    // first, we initialize the map
    this.mapElt = document.createElement("div");
    this.mapElt.style["margin-top"] = "100vh";
    this.mapElt.style.height = "750px";
    this.mapElt.style.width = "1000px";
    this.containerElt.appendChild(this.mapElt);
    this.legendElt = document.createElement("div");
    this.legendElt.style.display = "inline-block";
    this.legendElt.style.color = "black";
    this.legendElt.style["max-width"] = "180px";
    this.containerElt.appendChild(this.legendElt);

    // first, we need to get map bounds from dep or iris
    const mapOptions: any = {
      container: this.mapElt,
      style: BACKGROUND_STYLE,
      bounds: franceBounds,
      accessToken: environment.mapbox.token,
      preserveDrawingBuffer: true,
    };
    const { devicePixelRatio } = window;
    await setDevicePixelRatio(2);
    this.map = new MapboxGL.Map(mapOptions);

    // we wait for map load
    await new Promise((resolve) => this.map.on("load", resolve));
    await setDevicePixelRatio(devicePixelRatio);

    // then, we load sources
    this._loadMapSources();

    const filter = this._getIrisFilter();

    // then, we add a layer to find where they are
    this.map.addLayer({
      id: "iris-fill",
      type: "fill",
      source: this.irisSource.name,
      "source-layer": this.irisSource.name,
      filter,
    });

    await this._waitForMapIdle();

    const visibleFeatures: any = this.map.querySourceFeatures(
      this.irisSource.name,
      {
        sourceLayer: this.irisSource.name,
        filter,
      }
    );
    if (visibleFeatures.length === 0) {
      throw Error("Found no matching feature");
    }


    const bounds = visibleFeatures
      .flatMap((feat) => {
        const { type, coordinates } = feat.geometry;
        if(type === "Polygon"){
          return coordinates.flat();
        }else if(type === "MultiPolygon"){
          return coordinates.flat().flat();
        }
        throw Error("Unsupported geometry");
      })
      .reduce(
        (bounds, point) => [
          [Math.min(bounds[0][0], point[0]), Math.min(bounds[0][1], point[1])],
          [Math.max(bounds[1][0], point[0]), Math.max(bounds[1][1], point[1])],
        ],
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity],
        ]
      );

    this.map.fitBounds(addBoundsMargin(bounds, 0.15));

    await this._waitForMapIdle();

    // irises may be split into chunks accross tiles. We need to rebuild them.
    const allIrisFeatures = this.map.querySourceFeatures(this.irisSource.name, {
      sourceLayer: this.irisSource.name,
      filter,
    });

    const groupedByIris = groupBy(
      allIrisFeatures,
      (feat) => feat.properties.code_iris
    );

    this.irisFeatures = Object.values(groupedByIris).map(mergePolygons);

    this.depFeature = mergePolygons(this.irisFeatures);

    this.depFeature = polygonBuffer(this.depFeature, 0.2);
    this.depFeature = polygonBuffer(this.depFeature, -0.2);

    this.map.removeLayer("iris-fill");

    // then, we add dep layer and take dep screenshot
    this.map.addLayer(
      {
        id: "deps-fill",
        type: "fill",
        source: {
          type: "geojson",
          data: this.depFeature,
        },
        paint: {
          "fill-color": "green",
          "fill-opacity": 0.3,
          "fill-outline-color": "#000000",
        },
      },
      "road-primary"
    );

    await this._waitForMapIdle();

    this.elmData.depImg = await this._takeMapShot();
    this.map.removeLayer("deps-fill");
  }

  async _generateCompetitionData() {
    const { token, geostrat } = environment.mapbox;
    const { style } = geostrat;
    const styleId = style.split("//styles/")[1];
    const url = `https://api.mapbox.com/styles/v1/${styleId}?access_token=${token}`;
    await fetch(url); // this preload the style, and prevent later resource conflicts
    // see https://github.com/mapbox/mapbox-gl-js/issues/10534 for explanation
    this.map.setStyle(style);
    await this._waitForMapIdle();

    // first, we get shops data

    const piscinistsLayer: any = this.map.getLayer("piscinistes");

    const { source, sourceLayer } = piscinistsLayer;

    const piscinists = this.map.querySourceFeatures(source, {
      sourceLayer,
    });

    const shopsInDep = piscinists
      .filter((feat: any) => pointInPolygon(feat.geometry, this.depFeature))
      .filter(
        (feat: any, index: number, array: any[]) =>
          index ===
          array.findIndex(
            (_) => _.properties.address === feat.properties.address
          ) // remove duplicates
      );

    this.map.setStyle(BACKGROUND_STYLE);
    await this._waitForMapIdle();

    this._loadMapSources();

    this.map.addSource("piscinists", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: shopsInDep.map((feat) => ({
          type: "Feature",
          properties: feat.properties,
          geometry: feat.geometry,
        })),
      },
    });

    const nbPoolsStat = stats.find((_) => _.property === "nb_pools");

    await this._setStatMap(nbPoolsStat);

    this.map.addLayer({
      id: "piscinists-symbol",
      type: "symbol",
      source: "piscinists",
      layout: {
        "icon-image": "border-dot-13",
        "icon-allow-overlap": true,
        "icon-size": 1,
      },
    });

    await this._waitForMapIdle();

    this.elmData.competition = {
      map: await this._takeMapShot(),
      data: shopsInDep.map((feat) => feat.properties),
    };

    this.map.removeLayer("piscinists-symbol");
    this.map.removeLayer("iris-fill");
    this.map.removeLayer("iris-line");
    this.map.removeSource("piscinists");
  }

  async generate(elm: Elm) {
    const { irises, dep, zipCodes } = elm;
    if ([irises, dep, zipCodes].filter(isInputValid).length !== 1) {
      throw Error("irises OR depCode OR zipCodes must be defined");
    }

    this.dep = dep;
    this.irises = irises;
    this.zipCodes = zipCodes;

    document.body.style.overflow = "hidden";
    document.body.style["pointer-events"] = "none";
    try {
      await this._initMapData();
      this.logo = await this._getLogo();
      if (this.logo) {
        this.logoDimensions = await getImageDimensions(this.logo);
      }

      await this._generateCompetitionData();

      this.pres = new pptxgen();

      this.pres.defineSlideMaster({
        title: "SLIDE_WITH_NUMBER",
        slideNumber: {
          x: 0.08,
          y: 5.19,
          w: 0.6,
          h: 0.43,
          color: "51B148",
          fontSize: 13,
          fontFace: "Dosis",
          align: "left",
        },
      });

      addSlide1(this);
      addSlide2(this);
      addSlide3(this);
      addSlide4(this);
      addSlide5(this);
      addSlide6(this);
      addSlide7(this);

      for (const stat of stats) {
        await this._generateIrisStat(stat);
        addStatSlides(this, stat);
      }

      addCompetitionSlides(this);

      addFinalSlide(this);
    } catch (e) {
      // cleaning
      if (this.map) {
        this.map.remove();
      }
      delete this.map;
      this.containerElt.innerHTML = ""; // we clear container
      document.body.style.overflow = "";
      document.body.style["pointer-events"] = "";
      throw e;
    }

    // cleaning
    this.map.remove();
    delete this.map;
    this.containerElt.innerHTML = ""; // we clear container
    document.body.style.overflow = "";
    document.body.style["pointer-events"] = "";

    return await this.pres.write();
  }
}

export default ELMGenerator;
